import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import '../styling/SliderImage.css';
import { parseImagesJson } from '../utils/imageHelper';

function SliderImage({data}) {
  return (
   <AwesomeSlider className="awesome-slider" organicArrows={false} >
      {parseImagesJson(data.images).map((image, index) => (
        <div className="image-containers" key={index}>
          <img className="slider-image" src={image} alt={`image-${index}`} />
        </div>
      ))}
    </AwesomeSlider>
  )
}
export default SliderImage;
