const 
    crore = 10000000, 
    lakh = 100000;

export function getReadableIndianNumber(price) {
    const priceInCrore = price / crore, priceInLakh = price / lakh;
    if (priceInCrore >= 1) {

        return "" + (priceInCrore) + "Cr";
    }
    
    if (priceInLakh >= 1) {
        return "" + (priceInLakh) + "L";
    }

    return price
}

export function isNumber(value) {
    return /^[+-]?\d+(\.\d+)?$/.test(value);
}
