export function calculateDaysAgo(createdAt) {
  const currentDate = new Date();
  const publishedDate = new Date(createdAt);
  const timeDifference = currentDate - publishedDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  if (secondsDifference < 60) {
    return `${secondsDifference} second${secondsDifference > 1 ? 's' : ''} ago`;
  } else if (minutesDifference < 60) {
    return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
  } else if (hoursDifference < 48) {
    return "Yesterday";
  } else {
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return `${daysDifference} days ago`;
  }
};

export function getRelativeDate(createdAt) {
  const currentDate = new Date();
  const publishedDate = new Date(createdAt);
  const timeDifference = currentDate - publishedDate;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  if (secondsDifference < 60) {
    return `${secondsDifference} second${secondsDifference > 1 ? 's' : ''} ago`;
  } else if (minutesDifference < 60) {
    return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
  }
  
  return publishedDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).replace(',', '');
}