export function parseImagesJson(images) {
    if (typeof images !== 'string') return images;

    return JSON.parse(images);
}

export async function compressImage(file) {
    const image = new Image();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            image.src = event.target.result;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = 800; // Set a maximum width for the compressed image
                const scaleFactor = Math.min(maxWidth / image.width, 1);

                canvas.width = image.width * scaleFactor;
                canvas.height = image.height * scaleFactor;

                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(
                    (blob) => {
                        resolve(blob);
                    },
                    'image/jpeg', // You can change the format to other compressed formats if needed
                    0.7 // Adjust the compression quality (0.7 is a good balance between size and quality)
                );
            };
        };

        reader.onerror = (error) => {
            resolve(file);
        };

        reader.readAsDataURL(file);
    });
}